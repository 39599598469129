import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  OverlayTrigger,
  Pagination,
  Popover,
  Spinner,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PageTitle from "../../hooks/PageTitle";
import { UseUserAdministrators } from "../../hooks/UseUserAdministrators";
import BodyTitle from "../bodyTitle/BodyTitle";
import DeleteModal from "../modal/DeleteModal";
import "./Home.css";
import "./HomePageItemBar.css";

const HomePage = () => {
  const userRole = UseUserAdministrators();
  const { REACT_APP_EXPRESS_BACKEND_URL } = process.env;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCounte] = useState(1);
  const [limit, setLimit] = useState(25);
  const [refetch, setRefetch] = useState(false);
  const [sort, setSort] = useState("");
  const [products, setProducts] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [companyList, setCompanyList] = useState({});
  const [company, setCompany] = useState([]);
  const nagivate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const inputRef = useRef(null);

  const [companyFilter, setCompanyFilter] = useState("");
  const [author, setAuthor] = useState("");

  // Delete Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [filterModal, setFilterModal] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (searchText && sort) {
      axios
        .get(
          `/productroute/search?search=${searchText}&page=${page}&limit=${limit}&sort=${sort}`,
          { withCredentials: true }
        )
        .then((res) => {
          setProducts(res.data.product);
          setPageCounte(res.data.pageCount);
          setIsLoading(false);
        });
    } else if (searchText) {
      axios
        .get(
          `/productroute/search?search=${searchText}&page=${page}&limit=${limit}`,
          { withCredentials: true }
        )
        .then((res) => {
          setProducts(res.data.product);
          setPageCounte(res.data.pageCount);
          setIsLoading(false);
        });
    } else if (sort) {
      axios
        .get(`/productroute?page=${page}&limit=${limit}&sort=${sort}`, {
          withCredentials: true,
        })
        .then((res) => {
          setProducts(res.data.product);
          setPageCounte(res.data.pageCount);
          setIsLoading(false);
        });
    } else {
      axios
        .get(`/productroute?page=${page}&limit=${limit}`, {
          withCredentials: true,
        })
        .then((res) => {
          setProducts(res.data.product);
          setPageCounte(res.data.pageCount);
          setIsLoading(false);
        });
    }
  }, [page, limit, refetch, sort, searchText]);
  useEffect(() => {
    axios.get(`/userroute`, { withCredentials: true }).then((res) => {
      setUserList(res.data.users);
    });
  }, []);

  useEffect(() => {
    axios
      .get(`/companyroute`, {
        withCredentials: true,
      })
      .then((res) => {
        const countryData = res.data.company;
        setCompany(countryData);
        let countryObj = {};
        countryData.forEach((company) => {
          countryObj = {
            ...countryObj,
            [company._id]: company.companyname,
          };
        });
        setCompanyList(countryObj);
      });
  }, [refetch, page, limit]);

  let active = page;
  let items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const paginationNumber = (e) => {
    const pageNumber = parseInt(e.target.innerText);
    setPage(pageNumber);
  };
  const handleLimit = (e) => {
    setLimit(parseInt(e.target.value));
  };

  const handleDelet = (id, name) => {
    setName(name);
    setId(id);
    handleShow();
  };

  const deleteConfirm = () => {
    axios
      .delete(`/productroute/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data) {
          toast.success(res.data, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRefetch(!refetch);
          handleClose();
        }
      });
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const deleyCall = useCallback(debounce(handleSearchText), []);

  const filterConfirmModal = () => {
    setFilterModal(!filterModal);
  };

  const sortingTable = (data) => {
    if (sort == data) {
      setSort("");
    } else {
      setSort(data);
    }
  };

  const handleFilterSubmit = () => {
    setIsLoading(true);
    setSearchText("");
    let url = "";

    if (companyFilter){
      url = `company=${companyFilter}`
    } else if (!companyFilter && author) {
      url = `author=${author}`
    } else if (companyFilter && author) {
      url = `company=${companyFilter}&author=${author}`;
    }
    
    axios
      .get(`/productroute/filter?${url}`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.product.length > 0) {
          setProducts(res.data.product);
          setIsLoading(false);
          filterConfirmModal();
        } else {
          setIsLoading(false);
          toast.warn("No product found", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setPageCounte(res.data.pageCount);
      });
  };

  const clearFilterData = () => {
    setIsLoading(true);
    setCompanyFilter();
    setAuthor();
    axios
      .get(`/productroute?page=${page}&limit=${limit}&sort=${sort}`, {
        withCredentials: true,
      })
      .then((res) => {
        setProducts(res.data.product);
        setPageCounte(res.data.pageCount);
        setIsLoading(false);
      });

    filterConfirmModal();
  };

  const clearSearchText = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setSearchText("");
    setPage(1);
  };

  return (
    <div>
      <PageTitle title="Home" />
      <BodyTitle />

      <div className="home_page_item_area">
        <div className="container">
          <div className="d-flex justify-content-between dashboard_item">
            {/* <h3>Product Overview</h3> */}
          </div>
          <div className="home_page_item d-flex justify-content-between">
            <div className="home_page_item_left">
              <Button
                onClick={() => nagivate("/editor")}
                variant="primary"
                className="primary_bg"
              >
                Create new
              </Button>
            </div>
            <div className="home_page_item_right d-flex">
              <i
                onClick={filterConfirmModal}
                title="Filter"
                role="button"
                className={`fa fa-filter ${
                  companyFilter || author ? "active_filter" : ""
                }`}
                aria-hidden="true"
              ></i>
              <OverlayTrigger
                trigger="click"
                rootClose
                key="top"
                placement="top"
                overlay={
                  <Popover id="popover-positioned-bottom">
                    <Popover.Body className="body_prop_area">
                      Search by Product ID, ID, Company, and Author
                    </Popover.Body>
                  </Popover>
                }
              >
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </InputGroup.Text>
                  <Form.Control
                    onChange={deleyCall}
                    placeholder="By Product ID, ID, Company, and Author..."
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    ref={inputRef}
                  />{" "}
                  {searchText && (
                    <InputGroup.Text
                      className="clear_src_button"
                      id="basic-addon2"
                    >
                      <i
                        onClick={clearSearchText}
                        title="Clear the search"
                        class="fa fa-times-circle"
                        aria-hidden="true"
                      ></i>
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <br />
            <br />
            {products.length > 0 ? (
              <Table className="scanJob_table" bordered size="lg">
                <thead>
                  <tr>
                    <th>Thumbnail</th>
                    <th
                      className={
                        sort === "clientProductId" ? "clientProductId" : ""
                      }
                      role="button"
                      onClick={() => sortingTable("clientProductId")}
                    >
                      Product ID
                    </th>
                    <th
                      className={sort === "_id" ? "proid" : ""}
                      role="button"
                      onClick={() => sortingTable("_id")}
                    >
                      ID
                    </th>
                    <th
                      className={sort === "company" ? "company" : ""}
                      role="button"
                      onClick={() => sortingTable("company")}
                    >
                      Company
                    </th>
                    <th
                      className={sort === "authordesc" ? "author" : ""}
                      role="button"
                      onClick={() => sortingTable("authordesc")}
                    >
                      Author
                    </th>
                    <th
                      className={sort === "createdAt" ? "createdAt" : ""}
                      role="button"
                      onClick={() => sortingTable("createdAt")}
                    >
                      Created at
                    </th>
                    <th
                      className={sort === "updatedAt" ? "updatedAt" : ""}
                      role="button"
                      onClick={() => sortingTable("updatedAt")}
                    >
                      Updated at
                    </th>
                    {userRole ? <th>Actions</th> : ""}
                  </tr>
                </thead>
                <tbody>
                  {products &&
                    products.map((p) => {
                      return (
                        <tr key={p._id}>
                          <td>
                            {p?.playerSettings?.thumbnail ? (
                              <img
                                width={22}
                                src={p?.playerSettings?.thumbnail}
                                alt=""
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>{p?.clientProductId}</td>
                          <td>{p?._id}</td>
                          <td>
                            {companyList[p?.company]
                              ? companyList[p?.company]
                              : companyList[p?.company?._id]}
                          </td>
                          <td>{p?.author?._id ? p?.author?._id : p?.author}</td>
                          <td>
                            {new Date(p?.createdAt).toLocaleString("en-US")}
                          </td>
                          <td>
                            {p?.updatedAt
                              ? new Date(p?.updatedAt).toLocaleString("en-US")
                              : "-"}
                          </td>
                          {userRole ? (
                            <td className="actions_btns">
                              <span
                                onClick={() =>
                                  nagivate(
                                    `/editor/${p?.company._id}/${p?.clientProductId}/${p?._id}`
                                  )
                                }
                                className="text-success"
                                title="Edit"
                                role="button"
                              >
                                <i
                                  className="fa fa-pencil-square"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <a
                                target="_blank"
                                href={`/player?client=${p?.company._id}&id=${p?.clientProductId}`}
                                className="text-primary"
                                title="Show"
                                role="button"
                                rel="noreferrer"
                              >
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </a>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <span
                                title="Delete"
                                role="button"
                                onClick={() =>
                                  handleDelet(p._id, p?.clientProductId)
                                }
                                className="text-danger"
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      );
                    })}
                </tbody>
                {isLoading && (
                  <div className="scanJob_filter_loader">
                    <>
                      <Spinner size="lg" animation="border" />
                      &nbsp; <span>Loading ScanJob...</span>
                    </>
                  </div>
                )}
              </Table>
            ) : (
              <h1 className="text-center">Sorry, no products found!</h1>
            )}

            <div className="pagination_product">
              <Form.Select
                disabled={products?.length < 5}
                onChange={handleLimit}
                className="limit_select-lg"
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Form.Select>
              <Pagination size="lg" onClick={paginationNumber}>
                {items}
              </Pagination>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        name={name}
        show={show}
        handleClose={handleClose}
        deleteConfirm={deleteConfirm}
      />

      {/* // Filter modal */}
      <Modal
        show={filterModal}
        onHide={filterConfirmModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="filter_modal_body"
        size="md"
      >
        <Modal.Header className="ian_modal" closeButton>
          <Modal.Title
            className="ian_modal_title"
            id="contained-modal-title-vcenter"
          >
            Filter
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter_modal_body">
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mt-4" controlId="formBasicIan">
                  <Form.Label>Company</Form.Label>
                  <Form.Select
                    value={companyFilter}
                    onChange={(e) => setCompanyFilter(e.target.value)}
                    aria-label="Default select example"
                  >
                    <option key="temp" value="">
                      {" "}
                      -none-
                    </option>
                    {company &&
                      company.map((c, index) => (
                        <option key={c._id} value={c._id}>
                          {c.companyname}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mt-4" controlId="formBasicIan">
                  <Form.Label>Author</Form.Label>
                  <Form.Select
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                    aria-label="Default select example"
                  >
                    <option key="temp" value="">
                      {" "}
                      -none-
                    </option>
                    {userList &&
                      userList.map((u, index) => (
                        <option key={u._id} value={u._id}>
                          {u.username}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
            <div className="d-flex justify-content-between ian_submit_btn">
              <span
                onClick={clearFilterData}
                className="cancle_style_button cancle_modal"
              >
                Clear filter
              </span>
              <button
                onClick={handleFilterSubmit}
                type="submit"
                className="btn btn-primary submit_filter"
              >
                Apply filter{" "}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HomePage;
